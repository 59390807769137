import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <h2>{`¿Por qué Tumkapps?`}</h2>
    <blockquote>
      <p parentName="blockquote">{`En Tumkapps encontrarás personas amables con la intensión de apoyarte y orientarte en la mejor solución a tu negocio.`}</p>
    </blockquote>
    <p>{`No importa si quieres una aplicación, una tienda en linea, registrar la entrada de tus empleados con QR, llevar en tu celular las calificaciones de tus alumnos, vender directo en tu tienda, imprimir etiquetas para tus productos, obtener reportes de ventas, costos, software administrativo, agendar citas de tus clientes... En fin, cualquier actividad que se te ocurra, con gusto la hacemos realidad.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Atención pos-venta`}</p>
    </blockquote>
    <p>{`No nos quedamos sólo en el desarrollo, sabemos lo importante de contar con una respuesta cuando surgen dudas con tu app o página. Por eso te brindamos atención después de la venta, resolvemos tus dudas y atendemos tus necesidades.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Comodidad para tus usuarios`}</p>
    </blockquote>
    <p>{`Creamos una experiencia agradable a los usuarios en todas las plataformas, agradables a la vista, fácil de usar y con gran funcionalidad.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Tecnologías de última generación`}</p>
    </blockquote>
    <p>{`Utilizamos las herramientas más modernas y seguras para brindarte mayor comodidad, velocidad y seguridad. Compatibles con los principales dispositivos como Android, IOS, Windows, Mac, etc.`}</p>
    <br />
    <p>{`¿Quieres mas información? Por favor escríbenos a `}<a parentName="p" {...{
        "href": "https://wa.me/523314542302"
      }}>{`whatsapp`}</a>{` o llama al `}<a parentName="p" {...{
        "href": "tel:523314542302"
      }}>{`3314542302`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      