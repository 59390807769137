import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import imageUrl from '../../../../static/logo.png';
import "../../../../styles/whatsapp.css";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img src={imageUrl} alt="Image alt" />
    <p>{`— Del Maya "T.u.m Ka apps" (sistemas y aplicaciones)`}</p>
    <hr></hr>
    <div>
	<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
	<a href="https://api.whatsapp.com/send?phone=523314542302" rel="noreferrer" className="float" target="_blank">
		<i className="fa fa-whatsapp my-float"></i>
	</a>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      