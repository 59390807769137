import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <h2>{`Haz realidad esa gran idea`}</h2>
    <p>{`Escríbenos a `}<a parentName="p" {...{
        "href": "https://wa.me/523314542302"
      }}>{`whatsapp`}</a>{` o llama al `}<a parentName="p" {...{
        "href": "tel:523314542302"
      }}>{`3314542302`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      