import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <br /><br /><br /><br /><br /><br />
    <h2>{`Nos especializamos en`}</h2>
    <ProjectCard title="Aplicaciones" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Te ayudamos a que tus proyectos sean escalables y efectivos con tecnologías de alta gama y calidad en tu proyecto 💪
    </ProjectCard>
    <ProjectCard title="Tiendas en linea" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  ¿Hay algo mejor que vender sin salir de tu hogar? con una tienda en linea de alta calidad puedes vender todos tus productos estés donde estés 💳
    </ProjectCard>
    <ProjectCard title="Páginas personales" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Ahora, si lo que quieres es una página para darle a tu negocio presencia en linea, mostrar tus servicios y productos con el mejor diseño y hacer crecer tu marca ¡Puedes contar con nosotros! 😉
    </ProjectCard>
    <ProjectCard title="Software personalizado" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  ¿Quieres un sistema administrativo, un CRM, un punto de venta, un POS, un lugar donde tus clientes puedan hacer sus citas? 📱
    </ProjectCard>
    <p>{`Si quieres saber más escríbenos a `}<a parentName="p" {...{
        "href": "https://wa.me/523314542302"
      }}>{`whatsapp`}</a>{` `}</p>
    <p>{`O llama al `}<a parentName="p" {...{
        "href": "tel:523314542302"
      }}>{`3314542302`}</a></p>
    <br />
    <h2>{`¿Cómo funciona?`}</h2>
    <ProjectCard title="💡 Tu idea" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Cuéntanos ese gran plan que tienes en mente con lujo de detalles.
    </ProjectCard>
    <ProjectCard title="✍🏻 Análisis" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Después de escuchar tus grandes ideas. Hacemos una propuesta de cómo se podrá lograr.
    </ProjectCard>
    <ProjectCard title="💪 Manos a la obra" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Una vez aprobada la propuesta, es momento de hacer lo que mejor sabemos hacer 🤓
    </ProjectCard>
    <ProjectCard title="✅ Entrega" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  ¡Ahora es tiempo de disfrutar tu idea echa realidad justo como la imaginaste!
    </ProjectCard>
    <p>{`Cuéntanos tu idea por `}<a parentName="p" {...{
        "href": "https://wa.me/523314542302"
      }}>{`whatsapp`}</a>{` `}</p>
    <p>{`O llama al `}<a parentName="p" {...{
        "href": "tel:523314542302"
      }}>{`3314542302`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      